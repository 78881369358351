textarea {
  outline: 0px none transparent;
}

textarea:focus,
input:focus {
  outline: 0;
}

*:focus {
  outline: 0;
}
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

p {
  font-size: 14px;
}
